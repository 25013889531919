<template>
<div>
  <div class="jazeSidePanel jazeSidePanel--global jazeSidePanel--shiftable jazeSidePanel--rightPanel rightPanelControl jazeSidePanel--fixedRight is-side-panel-open" style="--side-panel-width: 570px;" v-show='zoneModalShow'>
    <div class="jazePanelContainer jazePanelContainer--horizontal">
      <div class="jazeMainPanel jazeMainPanel--globalSidePanel unifiDndItem" draggable="true" style="left: 0px; top: 0px; width: 100%; z-index: 1;">
        <div class="jazeProperties jazeProperties--maximized">
          <div class="jazePanelContainer jazePanelContainer--horizontal gatewayProperties">
            <div class="jazeHeaderPanel jazeHeaderPanel--secondary jazeHeaderPanel--properties pr-0">
              <div class="jazeHeaderGroup noPointerEvents" style="--header-actions-width:65px;">
                <div class="jazeHeader jazeHeader--properties">
                  <div class="jazeHeader__title jazeHeader__title--properties">
                    <div class="type-jaze-title type-ellipsis py-1 mr-2"> Configure </div>
                  </div>
                </div>
                <div class="jazeHeader flex-none px-4">
                  <div class="jazeHeader__actions allPointerEvents">
                    <div class="jazeIconGroup jazeIconGroup--cozy jazeIconGroup--noSpacer">
                      <div class="unifiAuraIcon">
                        <svg class="jazeIcon jazeIcon--actionable" @click="onclosesubmit">
                          <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                            <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z"></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="jazeMainPanel jazeMainPanel--properties">
              <div class="jazePanelContent jazePanelContent--scrollable">
                <div class="unifiPanelItemContent">
                  <form ref='internetConfigForm' id='internetConfigForm' class="jazeForm unifiAddUserRight is-validation-hidden" :class="formClass">
                    <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                        <div class="col-6">
                          <strong>{{errorMessage}}</strong>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col--sm4">
                          <label class="jazeLabel">Choose Vendor</label>
                        </div>
                        <div class="col--sm8">
                          <treeselect name="vendorName" v-model="vendorVal" :options="vendors" :searchable="true" :clearable="false" @update:modelValue="getAttributes()"></treeselect>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col--sm4">
                          <label class="jazeLabel">Choose Attribute</label>
                        </div>
                        <div class="col--sm8">
                          <treeselect name="vendorAttribute" v-model="atributeVal" :options="attributes" :searchable="true" :clearable="false"></treeselect>
                        </div>
                      </div>
                      <button class="appMainButton appMainButton--tertiary appMainButton--transparent floatRight" type="button" id="addVendorAttribute" @click="addEditVendorAttribute('add')">
                          <i class="jaze-icon--plus"></i> <span class="">Add</span>
                      </button>
                      <div class="table-responsive">
                        <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Type </th>
                              <th>Vendor </th>
                              <th>Attribute </th>
                              <th>Equals </th>
                              <th>Value </th>
                              <th>Actions </th>
                            </tr>
                          </thead>
                          <tbody id="vendorTableBody">
                            <tr v-for="(data, index) in editData.vendorTablesData">
                              <td> {{index +1}} </td>
                              <td> {{data.VendorAttribute.type}} </td>
                              <td> {{data.VendorAttribute.vendor}} </td>
                              <td> {{data.VendorAttribute.attribute }} </td>
                              <td> {{data.VendorAttribute.expression}} </td>
                              <td> {{data.VendorAttribute.value}} </td>
                              <td>
                                <div class="jazeButtonGroup">
                                <button title="Edit" type="button" class="jazeButton jazeButton--link jazeButton--noWrap mr-4">
                                    <span class="button__icon icon jaze-icon--pencil" style="margin-right:0px" @click="addEditVendorAttribute('edit',data.VendorAttribute)"></span>
                                </button>
                                <button title="Delete" type="button" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" @click="deleteVendor(data.VendorAttribute, index)">
                                    <span class="button__icon icon jaze-icon--trashcan" style="margin-right:0px"></span>
                                </button>
                                </div>                                  
                              </td>
                            </tr>
                          </tbody>
                          <input name="vendorTableData" type="hidden" :value="vendorTableData">
                          <input name="configType" type="hidden" value="non-payment">
                          <input name="billAccountId" type="hidden" :value="billAccountId">
                        </table>
                      </div>
                      <div class="">
                        <div class="jazeButtonGroup jazeButtonGroup--cozy floatRight">
                          <button class="jazeButton jazeButton--secondary" @click="onclosesubmit">Cancel</button>
                          <button class="jazeButton jazeButton--primary" type="button" @click='configSubmit'>Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-show="addEditVendorShow" style="display:flex">
      <div class="jazeModal unifiConfirm jazeModal--small">
        <div class="jazeModal__header pb-0">
          <div class="jazeHeader jazeHeader--centered">
            <div class="jazeHeader__title" v-if="type=='add'">Add Attribute</div>
            <div class="jazeHeader__title" v-else="">Edit Attribute</div>
            <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addEditVendorShow=false">
              <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="jazeModal__main">
          <form ref='attrForm' id='attrForm' class="appForm appForm--cozy is-validation-hidden">
            <div class="jazeModalContent pb-1">
              <fieldset class="appFieldset appFieldset--quiet">
                <div class="form-group appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxInline">
                      Type
                    </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <label v-if="attrType!=''"> {{attrType}} </label>
                    <label v-else> reply </label>
                  </div>
                </div>
                <div class="form-group appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxInline">
                      Vendor
                    </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <label v-if="attrVendor!=''"> {{attrVendor}} </label>
                    <label v-else> {{vendorVal}} </label> 
                  </div>
                </div>
                <div class="form-group appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxInline">
                      Attribute
                    </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <label v-if="attrAttribute!=''"> {{attrAttribute}} </label>
                    <label v-else-if="atributeVal!=null"> {{atributeVal}} </label>
                    <label v-else> empty </label> 
                  </div>
                </div>
                <div class="form-group appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxInline">
                      Equals
                    </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <select class="col--sm9" name="attrExpression">
                        <option value=":=" v-if="attrExpression==':='" selected> := </option>
                        <option value=":=" v-else> := </option>
                        <option value="=" v-if="attrExpression=='='" selected> = </option>
                        <option value="=" v-else> = </option>
                    </select>
                  </div>
                </div>
                <div class="form-group appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxInline">
                      Value
                    </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <input class="col--sm9" type="text" v-if="type=='add'" value="" name="attrValue"/> 
                    <input class="col--sm9" type="text" v-else :value="attrValue" name="attrValue"/> 
                  </div>
                </div>

              </fieldset>
            </div>
          </form>
        </div>
        <div class="jazeModal__footer">
          <div class="jazeButtonGroup jazeButtonGroup--cozy">
            <button class="jazeButton jazeButton--secondary" @click="addEditVendorShow=false">Cancel</button>
            <button class="jazeButton jazeButton--primary" type="button" @click="saveVendorConfig()">Yes</button>
          </div>
        </div>
      </div>
    </div>
     <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster> 

  </div>
</template>

<script setup>
import { ref,toRefs,defineProps,defineEmits,onMounted,watchEffect } from 'vue'
import axios from "@/api/index";
import qs from "qs";
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { usesettingsStore } from '@/store/settingsstore'

  const emit = defineEmits(['extend-clicked-zone-detail']);
  const props = defineProps(["vendors", "editData", "billAccountId"]);

  const { vendors, editData, billAccountId } = toRefs(props)
  const zoneModalShow = ref( true )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const vendorVal = ref( null )
  const atributeVal = ref( null )
  const attributes = ref( [] )
  const vendorTableData = ref( '' )
  const addEditVendorShow = ref( false )
  const types = ref( '' )
  const attrId = ref( '' )
  const attrType = ref( '' )
  const attrVendor = ref( '' )
  const attrAttribute = ref( '' )
  const attrExpression = ref( '' )
  const attrValue = ref( '' )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )

  const settingsStore = usesettingsStore()

  const applyMixin = () => {
    const mixin = overrideIsSelected();
    const context = {
      $options: {
        components: { Treeselect },
      },
    };
    mixin.mounted.call(context);
  };
  watchEffect(() => {
    applyMixin();
  })

  onMounted(() => {
    getVendorDetails();
  })

  const getVendorDetails = () => {
    vendorVal.value = 'none';
  }
  const onclosesubmit = () => {
    zoneModalShow.value = false;
    addEditVendorShow.value = false;
    emit("extend-clicked-zone-detail", zoneModalShow.value);
  }
  const getAttributes = () => {
    var data = {
      vendor: vendorVal.value
    };
    axios
      .post(
        "/settings/getConfigureVendorAttributes",
        qs.stringify(data, {
          parseArrays: false
        })
      )
      .then(response => {
        if(response.data.data!=null && response.data.data.length !=0) {
        attributes.value = [];
        atributeVal.value = null;
          for (let i = 0; i < response.data.data.length; i++) {
              attributes.value.push({
              'id': response.data.data[i],
              'label': response.data.data[i]
              });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  const checkForm = (vendorVal, atributeVal) => {
    if(vendorVal=='none' || atributeVal == null) {
      errorMessage.value = "Vendor/Attribute fields cannot be left empty";
      errorShow.value = true;
      return "error";
    }
  }
  const addEditVendorAttribute = (type, attrDetail=null) => {
      types.value = type;
      if(type=='add') {
        var status = checkForm(vendorVal.value,atributeVal.value);
        if (status == "error") {
          formClass.value = "is-form-validation-visible";
          return;
        }
      }
      errorShow.value = false;
      addEditVendorShow.value = true;
      if(attrDetail!=null) {
        attrId.value = attrDetail.id;
        attrType.value = attrDetail.type;
        attrVendor.value = attrDetail.vendor;
        attrAttribute.value = attrDetail.attribute;
        attrExpression.value = attrDetail.expression;
        attrValue.value = attrDetail.value;
      }  
  }
  const saveVendorConfig = () => {
    var form = document.getElementById('attrForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    var VendorAttribute = {};
    if(types.value=='add') {
      VendorAttribute["type"] = 'reply'; 
      VendorAttribute["vendor"] = vendorVal.value;
      VendorAttribute["attribute"] = atributeVal.value;
      VendorAttribute["expression"] = postData.attrExpression;
      VendorAttribute["value"] = postData.attrValue;
      if(editData.value.vendorTablesData!=undefined) {
        editData.value.vendorTablesData.push({'VendorAttribute': VendorAttribute});
      } else {
        editData.value.vendorTablesData = [];
        editData.value.vendorTablesData.push({'VendorAttribute': VendorAttribute});
      }
    } else {
      VendorAttribute["id"] = attrId.value;
      VendorAttribute["type"] = attrType.value;
      VendorAttribute["vendor"] = attrVendor.value;
      VendorAttribute["attribute"] = attrAttribute.value;
      VendorAttribute["expression"] = postData.attrExpression;
      VendorAttribute["value"] = postData.attrValue;
        for(let i=0;i<editData.value.vendorTablesData.length;i++) {
          if(editData.value.vendorTablesData[i].VendorAttribute.id == attrId.value) {
            editData.value.vendorTablesData[i].VendorAttribute = VendorAttribute;
          }
        }
    }
    addEditVendorShow.value=false;
  }
  const deleteVendor = (VendorAttribute,index) => {
    successtoaster.value = false;
    errortoaster.value = false;
    var id = VendorAttribute.id;
    if(id!=undefined && id !=null) {
      var data = {
          vendorId: VendorAttribute.id,
          type: VendorAttribute.type,
          vendor: VendorAttribute.vendor,
          attribute: VendorAttribute.attribute,
          expression: VendorAttribute.expression,
          value: VendorAttribute.value,
          billAccountId: billAccountId.value,
          configType: 'non-payment',
          type: 'billing'
      };
      // $store.dispatch("deleteVendorSubmit", data).then(
      settingsStore.deleteVendorSubmit(data).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
          console.log(error);
      }
      );          
    } else {
      if (index != '-1') {
        editData.value.vendorTablesData.splice(index, 1);
      }
    }
  }
  const configSubmit = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    for(let i=0;i<editData.value.vendorTablesData.length;i++) {
      vendorTableData.value += editData.value.vendorTablesData[i].VendorAttribute.type + ',' +
                              editData.value.vendorTablesData[i].VendorAttribute.vendor + ',' +
                              editData.value.vendorTablesData[i].VendorAttribute.attribute + ',' +
                              editData.value.vendorTablesData[i].VendorAttribute.expression + ',' +
                              editData.value.vendorTablesData[i].VendorAttribute.value + ';';
    }
    var form = document.getElementById('internetConfigForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    postData.type = types.value
    postData.vendorTableData = vendorTableData.value;
    // $store.dispatch("zoneConfigSubmit", postData).then(
    settingsStore.zoneConfigSubmit(postData).then(  
      response => {
        emit("extend-clicked-zone-detail", false);
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          1500
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }

</script>

