import { createApp,h } from 'vue'
import App from './App.vue'
import router from '@/router'
import axios from 'axios';
import 'nprogress/nprogress.css';
import 'vue-swatches/dist/vue-swatches.css'
import 'vue3-swatches/dist/style.css'
import 'nprogress/nprogress.js';
import Notifications from '@kyvg/vue3-notification'
import * as VueGoogleMaps from 'vue3-google-map' //installed vue3-google-map use this
//import {Loading} from 'vue3-loading-overlay'; // in all js file need to copy
//import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import 'jaze-vue3-treeselect/dist/style.css'
import helpers from "@/helpers/helpers";
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import 'jaze-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { createPinia } from 'pinia'
import VueGtag from 'vue-gtag'
//import * as Sentry from "@sentry/vue";

const app = createApp({
    render: () => h(App)
  });
  app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAZaKw4VI0yelXUEj2KsL97ZjpHX8bF3zU",
      libraries: "places"
    }
  });
  localStorage.setItem('socketConnected', "no");
const treeSelectplugin = {
  install() {
    app.helpers = helpers;
    app.config.globalProperties.$helpers = helpers;
  },
};
const optionsp = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
      speed: "0.2s",
      opacity: "0.6s",
      termination: 300,
    },
    autoRevert: true,
    location: "left",
    inverse: false,
  };
app.use(VueProgressBar,optionsp)
app.use(treeSelectplugin);
app.use(createPinia())
app.use(Notifications)
app.config.productionTip = false
const reqInterceptor = axios.interceptors.request.use(config => {
  console.log('Request Interceptor', config)
  return config
})
const resInterceptor = axios.interceptors.response.use(res => {
  console.log('Response Interceptor', res)
  return res
})
axios.interceptors.request.eject(reqInterceptor)
axios.interceptors.response.eject(resInterceptor)

app.directive('click-outside', {
  bind: function (el, binding, vNode) {

    const bubble = binding.modifiers.bubble;
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;
    document.addEventListener("click", handler);
  },

  unbind: function (el, binding) {
    document.removeEventListener("click", el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  }
})
window.addEventListener('build', function (e) {
  let adminId = localStorage.getItem("socAdmin")
  let socEnable = localStorage.getItem("socEnable")
  let socketConnected = localStorage.getItem("socketConnected")
  if (socEnable != null && socEnable != undefined && adminId != null && adminId != undefined && socketConnected == "no" && socEnable == "true") {
    const socket = io('https://' + window.location.hostname + ":" + location.port, { transports: ['websocket'] });
    socket.on('connect', function () {
      console.log("socket connected");
      localStorage.setItem('socketConnected', "yes");
      socket.emit('room', adminId + '-header');
      app.use(VueSocketIOExt, socket);
    })
  } else {
    localStorage.setItem('socketConnected', "no");
  }
}, false);

window.addEventListener('storage', (event) => {
  if (localStorage.getItem("token") === "logout") {
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('adminDetails')
    location.reload();
  }
});
// Sentry.init({
//   dsn: "https://50e2401fa922b0be60b0fd4293151427@o4506462067818496.ingest.sentry.io/4506462069194757",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost:8081", /^https:\/\/cloud\.jazenetworks\.com/, /^https:\/\/live\.jazenetworks\.com/, /^https:\/\/isp\.jazenetworks\.com/],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


app.use(router)
const hostname = window.location.hostname;
// Set default values based on hostname
if (hostname === 'cloud.jazenetworks.com' || hostname === 'live.jazenetworks.com' || hostname === 'isp.jazenetworks.com') {
  localStorage.setItem('useGoogleAnalytics', "yes");
  app.use(
    VueGtag,
    {
      config:{id:'UA-41407586-1',}
    },
    router
  );
} else {
  localStorage.setItem('useGoogleAnalytics', "no");
}
app.mount("#app");
